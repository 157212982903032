<template>
<div>
  <b-card>
    <!--overview  & tracker--->
    <b-row>
      <!--overview--->
      <b-col cols="6">
        <pia-overview />
      </b-col>

      <!--tracker--->
      <b-col cols="6">
        <pia-tracker />
      </b-col>
    </b-row>

    <!-- search & new -->
    <!-- 
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="所在行业"
          v-model="query_industry"
          :options="industryOptions"
        />
      </b-col>  
      <b-col cols="4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="名称"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button variant="primary" @click="doSearch"
          ><feather-icon icon="InfoIcon" class="mr-50" /><span
            class="align-middle"
            >刷新</span
          ></b-button
        >
      </b-col>
    </b-row>
     -->
</b-card>
<b-card>
    <!-- list -->
    <b-row>
      <!--data list--->
      <b-col cols="12">
        <b-table
          ref="listTable"
          :items="listTableItems"
          :fields="listTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          hover
          responsive
          :per-page="listTablePerPage"
          :current-page="listTableCurrentPage"
          class="mt-1"
          show-empty
          empty-text="没有找到符合要求的数据"
        >

          <template #cell(actions)="data">
            <div class="text-right text-nowrap">
              <b-button
                variant="flat-success"
                class="btn-icon"
                @click="viewItem(data)"
                ><feather-icon icon="EyeIcon"
              /></b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <!--pagination--->
      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
  </div>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BMedia,
} from "bootstrap-vue";
import PiaOverview from "./PIAOverview.vue";
import PiaTracker from "./PIATracker.vue";

// import { avatarText } from '@core/utils/filter'

export default {
  name: "PIAList",

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    PiaOverview,
    PiaTracker,
  },

  data() {
    return {
      queryData: null,
      // select options
      catalogOptions: [
        { value: "", text: "法律法规" },
        "PIPL",
        "GDPR",
        "PIPL",
        "其他",
      ],
      levelOptions: [
        { value: "", text: "进度" },
        "填写中",
        "已完成",
        "其他",
      ],

      // query
      query_catalog: "",
      query_level: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      sortBy: "index",
      sortDesc: false,
      listTableFields: [
        { key: "id", label: "序号" },
        { key: "title", label: "名称" },
        { key: "summary", label: "简介" },
        { key: "compliance", label: "法律法规" },
        { key: "ownerName", label: "发起人" },
        { key: "start", label: "开始日期" },
        { key: "end", label: "结束日期" },
        { key: "progress", label: "进度" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 10,
      listTablePageOptions: [10, 15, 30],
      listTableTotalRows: 10,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      // debugger
      this.$http
        .get("/pias").then((res) => {
          this.queryData = res.data.data.list;
          this.listTableTotalRows = res.data.data.total;
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>